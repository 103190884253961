@import 'assets/scss/variables';
@import 'assets/scss/mixins';

.eighth {
  &__header {
    text-align: center;
    width: 100%;

    @include maxW_425 {
      text-align: left;
      margin-top: 75px;
    }
  }

  &__title {
    @include createNewOfferTitle(36px);

    @include maxW_425 {
      margin-bottom: 10px;
      font-family: $main-font;
      font-size: 24px;
    }
  }

  &__subtitle {
    font-family: $main-font;
    font-size: 21px;
    line-height: 26px;
    color: $main-white;

    @include maxW_425 {
      font-size: 14px;
    }
  }

  &__range {
    @include displayFlex(null, center);
    gap: 15px;
    padding: 0 10px;
  }

  &__years {
    color: $main-white;
    font-weight: 300;
    min-width: 100px;
  }

  &__selects {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    padding: 20px 10px;

    @include maxW_425 {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  &__selectContainer {
    @include displayFlex(center, null, column);
    gap: 8px;
  }

  &__tooltipHeading {
    margin: 0;
    padding-bottom: 2px;
    color: $main-black;
    font: 400 14px $main-font;
  }

  &__tooltipParagraph {
    margin: 0;
    padding-bottom: 10px;
    font: 400 12px $main-font;
  }

  &__tooltipLink {
    color: $main-black;
    font-size: 14px;
    font-weight: 600;
    text-decoration: none;
  }

  &__textarea {
    padding: 0 10px 50px;
  }
}
