@import './quoteTermsForm.scss';
@import './LenderView.scss';

body {
  min-height: 100vh;
  margin: 0;
  background-color: $main-white;
  font-family: $main-font;

  p {
    margin: 0;
  }

  .inputValid {
    border: 1px solid $custom-input-focused-border;
  }

  .fullWidth {
    width: 100%;
  }

  .fw600 {
    font-weight: 600;
  }

  .text-bold {
    font-weight: 700;
  }

  .fs18 {
    font-size: 18px;
  }

  .hidden {
    visibility: hidden;
  }

  .d-none {
    display: none;
  }

  .alignCenter {
    align-self: center;
  }

  .mb35 {
    margin-bottom: 35px;
  }

  .mb25 {
    margin-bottom: 25px;
  }

  .mb20 {
    margin-bottom: 20px;
  }

  .mb15 {
    margin-bottom: 15px;
  }

  .mb10 {
    margin-bottom: 10px;
  }

  .mt35 {
    margin-top: 35px;
  }

  .mt25 {
    margin-top: 25px;
  }

  .gap25 {
    gap: 25px;
  }

  .gap5 {
    gap: 5px;
  }

  .flexWrap {
    flex-wrap: wrap;
  }

  .link {
    text-decoration: underline;
    color: $main-link;
    cursor: pointer;

    @include maxW_425 {
      font-size: 14px;
    }
  }
}
