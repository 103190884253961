@import 'assets/scss/variables';
@import 'assets/scss/mixins';

.searchBar {
  @include displayFlex(space-evenly, center, column);
  height: 150px;
  padding-bottom: 20px;
  background: $main-white;
  border-bottom: 1px solid $border;

  @include maxW_425 {
    padding-bottom: 0;
  }

  &__label {
    margin-bottom: 5px;
    font-size: 14px;
    color: $custom-input-label;

    @include maxW_425 {
      font-size: 16px;
    }
  }

  &__search {
    @include displayFlex(center, null, column);

    @include maxW_425 {
      margin-left: 10px;
    }
  }

  &__field {
    position: relative;
  }

  &__item {
    width: 100%;
    box-sizing: border-box;
    padding: 0 30px;
    height: 42px;
    border: 1px solid $custom-input-border;
    border-radius: 5px;
    outline: none;
    text-align: start;
    font: 14px $quote-terms-secondary-font;

    &:focus {
      @include inputFocused;
    }

    @include maxW_768 {
      width: 250px;
    }
  }

  &__btnGroup {
    @include maxW_1080 {
      @include displayFlex(center);
    }
  }

  &__group {
    @include maxW_768 {
      @include displayFlex(flex-start);
    }
  }

  &__searchIcon {
    position: absolute;
    top: 10px;
    left: 5px;
    color: $main-gray;
  }

  &__sideMenu {
    display: none;

    @include maxW_768 {
      @include displayFlex(center);
      margin-top: 15px;
    }
  }

  &__buttons {
    @include displayFlex(flex-start, center);
    margin-top: 5px;
    color: #136849;
    gap: 10px;

    @include maxW_1080 {
      gap: 5px;
    }
  }

  &__smallScreen {
    @include maxW_425 {
      width: 350px;
    }
  }

  &__hide {
    @include maxW_768 {
      display: none;
    }
  }
}

.toolBar {
  width: 100%;
  height: 60px;
  position: relative;

  @include maxW_425 {
    height: auto;
  }

  &__header {
    color: $light-gray;
    position: absolute;
    left: 0;
    top: 0;

    & h1 {
      margin: 0;
    }

    @include maxW_425 {
      position: absolute;
      left: 10px;
      top: -30px;
    }
  }

  &__nav {
    position: absolute;
    right: 0;
    bottom: 0;

    @include maxW_768 {
      display: none;
    }
  }
}
