@import 'assets/scss/mixins';
@import 'assets/scss/variables';

.nonBindingOffer {
  padding: 0 20px 35px;
  border-radius: 10px;
  background-color: $main-white;

  &__infoBanner {
    @include displayFlex(center);
    padding: 10px 0;
    border: 1px solid #b3d5f8;
    border-radius: 5px;
    background-color: rgba(179, 213, 248, 0.3);
  }

  &__dataTable {
    @include displayFlex();
    flex-wrap: wrap;
    row-gap: 35px;
  }
}

.lenderProfile {
  @include displayFlex(null, center);
  gap: 20px;

  &__image {
    height: 60px;
    width: 60px;
    border-radius: 5px;
    background-color: $second-gray;
  }

  &__modified {
    color: $text-grey;
  }

  &__otherRequirements {
    @include displayFlex(null, null, column);
    gap: 5px;
  }
}

.navigationPanel {
  @include displayFlex(space-between, center);
  gap: 10px;

  @include maxW_425 {
    gap: 20px;
  }

  &__links {
    @include displayFlex(null, center);
    gap: 5px;
  }

  &__stack {
    @include displayFlex();
    gap: 20px;

    @include maxW_480 {
      gap: 10px;
      width: 100%;
    }

    @include maxW_375 {
      @include displayFlex(null, null, column);
    }
  }

  &__button {
    width: 180px;
    @include maxW_375 {
      width: 100%;
    }
  }
}
