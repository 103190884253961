/* Using instruction */

@mixin maxW_1920 {
  @media (max-width: 1920px) {
    @content;
  }
}

@mixin maxW_1536 {
  @media (max-width: 1536px) {
    @content;
  }
}

@mixin maxW_1440 {
  @media (max-width: 1440px) {
    @content;
  }
}

@mixin maxW_1366 {
  @media (max-width: 1366px) {
    @content;
  }
}

@mixin maxW_1280 {
  @media (max-width: 1280px) {
    @content;
  }
}

@mixin maxW_1080 {
  @media (max-width: 1080px) {
    @content;
  }
}

@mixin maxW_768 {
  @media (max-width: 768px) {
    @content;
  }
}

@mixin maxW_675 {
  @media (max-width: 675px) {
    @content;
  }
}

@mixin maxW_580 {
  @media (max-width: 580px) {
    @content;
  }
}

@mixin maxW_480 {
  @media (max-width: 480px) {
    @content;
  }
}

@mixin maxW_425 {
  @media (max-width: 425px) {
    @content;
  }
}

@mixin maxW_375 {
  @media (max-width: 375px) {
    @content;
  }
}

@mixin minW_1080 {
  @media (min-width: 1080px) {
    @content;
  }
}

@mixin minW_425 {
  @media (min-width: 425px) {
    @content;
  }
}

@mixin maxH_1080 {
  @media (max-height: 1080px) {
    @content;
  }
}

@mixin maxH_900 {
  @media (max-height: 900px) {
    @content;
  }
}

@mixin maxH_864 {
  @media (max-height: 864px) {
    @content;
  }
}

@mixin maxH_768 {
  @media (max-height: 748px) {
    @content;
  }
}

@mixin maxH_720 {
  @media (max-height: 720px) {
    @content;
  }
}

@mixin displayFlex($horizontal-position: initial, $vertical-position: initial, $flex-direction: initial) {
  display: flex;
  justify-content: $horizontal-position;
  align-items: $vertical-position;
  flex-direction: $flex-direction;
}

@mixin inputFocused {
  border: 1px solid $custom-input-focused-border;
}

@mixin createNewOfferTitle($font-size) {
  font-family: $createNewOffer-title-font;
  font-size: $font-size;
  font-weight: 500;
  color: $main-white;
}
