@import 'assets/scss/variables';
@import 'assets/scss/mixins';

.projectCard {
  @include displayFlex(null, null, column);
  position: relative;
  cursor: pointer;

  @include maxW_425 {
    width: 95%;
  }

  &__image {
    max-width: 400px;
    height: 280px;
    width: 100%;
    object-fit: fill;
  }

  &__addresses {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-top: 14px;
    text-align: left;
    height: 50px;
    width: 90%;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__name {
    font: 600 20px Poppins;
    color: #355c82;
  }

  &__street {
    margin: 0;
    font-size: 18px;
    font-weight: 300;
  }

  &__city {
    margin: 0;
    font-size: 20px;
    font-weight: 300;
  }

  &__budget {
    font-weight: 600;
    color: $main-gray;

    @include maxW_425 {
      display: block;
    }
  }

  &__status {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 2px 6px;
    border-radius: 5px;
    background-color: #0277bd;
    color: $main-white;
  }
}
