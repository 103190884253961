@import 'assets/scss/variables';
@import 'assets/scss/mixins';

.seventh {
  &__header {
    @include displayFlex(null, center, column);

    @include maxW_425 {
      @include displayFlex(null, flex-start, column);
      margin-top: 75px;
      padding-left: 10px;
    }
  }

  &__title {
    @include createNewOfferTitle(36px);

    @include maxW_425 {
      font-family: $main-font;
      font-size: 24px;
    }
  }

  &__subtitle {
    font: normal 400 21px/26px $main-font;
    color: $main-white;

    @include maxW_425 {
      font-size: 14px;
    }
  }

  &__rangeWrapper {
    @include displayFlex(null, center, row);
    padding: 40px 10px;

    @include maxW_425 {
      padding: 10px 14px;
    }
  }

  &__titleWrapper {
    padding: 0 30px 40px 0;

    @include maxW_425 {
      padding: 0 14px 40px 0;
    }
  }

  &__rangeTitle {
    margin-bottom: 14px;
    color: $main-white;
  }

  &__percent {
    max-width: 50px;
    color: $main-white;
    font-weight: 300;
  }

  &__secondaryHeader {
    @include maxW_425 {
      @include displayFlex(null, flex-start, column);
    }
  }

  &__secondaryTitle {
    margin-bottom: 13px;
    font: normal normal 24px/30px $main-font;
    color: $main-white;
    text-align: center;

    @include maxW_425 {
      margin-bottom: 10px;
      padding-left: 10px;
      font-size: 14px;
      line-height: 18px;
    }
  }

  &__secondarySubtitle {
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    color: $main-white;

    @include maxW_425 {
      padding-left: 10px;
    }
  }
}
