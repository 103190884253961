@import 'assets/scss/variables';
@import 'assets/scss/_mixins.scss';

.createNewProj {
  @include displayFlex(null, null, column);
  background-color: $main-white;
  cursor: pointer;

  @include maxW_768 {
    width: 95%;
    margin: 0;
  }

  &__add {
    margin: 100px 60px;
  }

  &__title {
    @include displayFlex(null, center, column);
    height: 278px;
    width: 100%;
    z-index: 100;
    color: $main-blue;
    border: 1px dashed #848484;

    @include maxW_580 {
      width: 70%;
    }

    @include maxW_425 {
      padding: 0;
      width: 100%;
    }
  }

  &__text {
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: $main-link;
  }

  &__addIcon {
    fill: $main-link;
    width: 50px;
    height: 50px;
  }

  &__addresses {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-top: 10px;
    text-align: left;
    height: 50px;
  }

  &__street {
    position: absolute;
    width: 300px;
    height: 26px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    color: #355c82;
  }

  &__city {
    margin: 0;
    font-size: 20px;
    font-weight: 300;
  }
}
