@import 'assets/scss/variables';
@import 'assets/scss/mixins';

.Checklist {
  min-height: 240px;
  padding: 0 20px;
  border: 1px solid #019875;
  border-radius: 10px;
  background-color: $main-white;

  @include maxW_425 {
    margin-top: 50px;
    border: 1px solid #dde4e4;
    border-radius: 0;
    padding-left: 10px;
  }

  &__completed {
    @include displayFlex(space-between, center, column);
    min-height: 150px;
    margin-top: -20px;
    font: 600 24px Poppins;
  }

  &__undo {
    font-size: 18px;
    color: $blue-text;
  }

  &__header {
    @include displayFlex(space-between, center);
  }

  &__heading {
    margin: 15px 0 15px;
    color: #8394a5;
    font: 500 30px Poppins;
  }

  &__link {
    color: $main-blue;
    text-decoration: underline;
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(3, auto);
    flex-wrap: wrap;
    gap: 20px;

    width: 90%;
    padding: 15px 0;

    @include maxW_425 {
      display: block;
    }

    input[type='checkbox'] {
      margin: 0;
      margin-right: 5px;
    }

    a {
      color: $main-blue;
    }
  }

  &__item {
    @include displayFlex(null, center);
    min-width: 180px;
    flex-wrap: nowrap;

    @include maxW_425 {
      margin-bottom: 10px;
    }
  }

  &__upload {
    input[type='file'] {
      display: none;
    }
  }

  &__icon {
    height: 14px;
    width: 14px;
    margin-left: 8px;
    cursor: pointer;
  }
}
