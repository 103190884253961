@import 'assets/scss/variables';
@import 'assets/scss/mixins';

.textArea {
  box-sizing: border-box;
  padding: 10px;
  border: 1px solid $textarea-border-color;
  border-radius: 5px;
  outline: none;
  font: 14px $quote-terms-secondary-font;

  &:focus {
    @include inputFocused;
  }

  &__white {
    background: none;
    border-color: $main-white;
    color: $main-white;
    font: normal normal 14px/18px $main-font;
  }

  &__label {
    color: $main-white;
    font: normal 600 14px/18px $main-font;
    font-size: 14px;
    padding-bottom: 5px;
  }
}
