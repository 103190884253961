@import 'assets/scss/variables';
@import 'assets/scss/mixins';

.attachments {
  padding: 10px 25px;

  &__text {
    margin-bottom: 30px;
    font-size: 14px;
  }

  &__heading {
    margin-bottom: 20px;
    font-weight: 600;
  }

  &__area {
    @include displayFlex(center, center);
    margin: 15px 0 25px;
    padding: 25px 20px;
    border: 1px $main-blue dashed;
    border-radius: 6px;
    background-color: #edf0f2;
    text-align: center;
  }

  &__upload {
    &__icon {
      height: 55px;
      width: 55px;
    }

    &__link {
      color: $main-blue;
      font-weight: bold;
      cursor: pointer;
    }

    &__size {
      font-size: 14px;
    }

    & input[type='file'] {
      display: none;
    }
  }
}

.attachment {
  @include displayFlex(space-between, center);
  height: 40px;
  margin-bottom: 20px;

  &__details {
    @include displayFlex(null, center);
    gap: 15px;
  }

  &__name {
    max-width: 400px;
    font-size: 16px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__date {
    font-size: 12px;
  }

  &__icon {
    margin-left: 10px;
    height: 20px;
    width: 20px;
    cursor: pointer;
  }
}
