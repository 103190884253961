@import 'assets/scss/variables';
@import 'assets/scss/mixins';

.radioGroup {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;

  @include maxW_375 {
    display: grid;
    grid-template-columns: repeat(2, minmax(140px, 1fr));

    &.reducedButtons {
      grid-template-columns: repeat(5, 30px);
    }

    &.burnOffTrigger button:nth-child(1) {
      grid-column: 1 / span 2;
    }

    &.penaltyType button:nth-child(1) {
      grid-row: 2 / 3;
    }

    &.penaltyType button:nth-child(2) {
      grid-column: 1 / span 2;
    }

    &.penaltyType button:nth-child(4) {
      grid-row: 4 / 5;
    }

    &.penaltyType button:nth-child(5) {
      grid-column: 1 / span 2;
    }

    &.loanFees button:nth-child(3) {
      grid-row: 4 / 5;
    }

    &.loanFees button:nth-child(4) {
      grid-column: 1 / span 2;
    }

    &.loanFees button:nth-child(5) {
      grid-column: 1 / span 2;
    }

    &.requiredReserves button:nth-child(3) {
      grid-column: 1 / span 2;
    }

    &.lockboxType button {
      grid-column: 1 / span 2;
    }
  }
}
