@import 'assets/scss/variables';
@import 'assets/scss/mixins';

.modal {
  display: none;

  @include maxW_425 {
    width: 360px;
    overflow-x: hidden;
  }

  &__header {
    @include displayFlex(space-between, null, null);
    border-bottom: 1px solid rgb(185, 182, 182);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    position: fixed;
    width: 800px;
    background-color: $main-white;
    opacity: 1;
    z-index: 101;

    @include maxW_768 {
      width: 100%;
    }
  }

  &__headerTitle {
    display: block;
    text-align: center;
    margin: 20px auto;
    font-family: $main-font;
    font-size: 24px;
    color: $main-gray;
  }

  &__footer {
    @include displayFlex(space-between, null, null);
    width: 794px;
    border-top: 1px solid rgb(185, 182, 182);
    background-color: $main-white;
    opacity: 1;
    position: sticky;
    bottom: 0;
    left: 0;

    @include maxW_425 {
      width: 390px;
    }
  }
}
