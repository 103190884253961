@import 'assets/scss/variables';
@import 'assets/scss/mixins';

.guarantors {
  &__data {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    gap: 20px;

    @include maxW_425 {
      grid-template-columns: 1fr;
      margin-right: 20px;
    }
  }

  &__container {
    display: grid;
    grid-template-columns: 1fr 2fr;
    margin: 10px 0;
    font-size: 14px;
  }

  &__row {
    @include displayFlex(flex-end, center);
    margin-bottom: 20px;
  }

  &__button {
    padding: 8px 25px;
    border-radius: 6px;
    background-color: $main-blue;
    color: $main-white;
    border: none;
    font-family: 'Source Sans Pro';
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
  }

  &__header {
    color: $blue-text;
    font-weight: 600;
    margin-bottom: 20px;
  }

  &__buttons {
    &_wrapper {
     @include displayFlex(space-between);
      width: 80px;
      margin-top: -10px;
    }

    &_divider {
      color: #0073E6;
      margin-bottom: 2px;
    }
  }

  &__title {
    color: $main-black;
    font-weight: 600;
  }

  &__subtitle {
    color: $boulder-text;
  }
}
