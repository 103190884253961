@import 'assets/scss/variables';
@import 'assets/scss/mixins';

.guarantors {
  position: relative;

  &__container {
    margin: 100px 40px 30px;
  }

  &__footer {
    @include displayFlex(flex-end);
    position: absolute;
    width: 90%;
    gap: 25px;
    bottom: 25px;

    @include maxW_425 {
      width: 80%;
    }
  }

  &__saveBtn {
    min-width: 130px;
    padding: 8px 25px;
    border-radius: 6px;
    background-color: $main-blue;
    color: $main-white;
    border: none;
    font-family: 'Source Sans Pro';
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
  }

  &__cancelBtn {
    min-width: 130px;
    padding: 8px 25px;
    background-color: transparent;
    color: $main-black;
    border-radius: 6px;
    border: 1px solid $main-black;
    font-family: 'Source Sans Pro';
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
  }
}
