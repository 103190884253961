// main styles
$main-background-color: #dde4e4;
$second-background-color: #ecf6ff;
$main-white: #fff;
$main-blue: #1c81ff;
$main-black: #000000;
$main-gray: #5c6166;
$second-gray: #d8d8d8;
$main-silver-chalice: #9e9e9e;
$border: #dde4e4;
$main-font: 'Source Sans Pro';
$createNewOffer-title-font: 'Poppins', serif;
$secondary-font: Poppins;
$tertiary-light-blue: #b3d5f8;

// link
$main-link: #0073e6;

// modal-gallery-slider
$modal-gallery-slider-background: #505050;

// stepper
$stepper-button-background: #edf0f2;

// info-banner
$options-button-background: rgba(255, 255, 255, 0.6);

// loan request
$loan-request-title-color: #019875;

// quote terms wrapper
$quote-terms-text: #848484;
$quote-terms-highlighted-text: #5999d8;
$quote-terms-stepper-color: #c7c7c7;
$quote-terms-stepper-thumb-active: #5999d8;
$quote-terms-title-font: Poppins;
$quote-terms-secondary-font: 'Source Sans Pro';
$quote-terms-section-border: #dde4e4;

// custom radio input
$radio-input-selected-background: #b3d5f8;
$radio-input-color: #848484;
$radio-input-border: #848484;

// custom input
$custom-input-border: #848484;
$custom-input-focused-border: #5999d8;
$custom-input-label: #757575;
$custom-input-sign: #848484;

// custom textarea
$textarea-border-color: #848484;

//font colors
$light-blue: #5999d8;
$blue-text: #0081ff;
$porcelain: #edf0f2;
$regent-gray: #8394a5;
$faded-red: #df4f60;
$dark-gray: #2e3033;
$cornflower-blue: #355c82;
$boulder-text: #787878;
$green-text: #229875;
$text-grey: #757575;
$light-gray: #848484;

//button text colors
$skip-button-text: #0073e6;
$save-button-text: #355c82;
