@import 'assets/scss/variables';
@import 'assets/scss/mixins';

.tenantInfo {
  &__heading {
    color: $main-blue;
    font-weight: bold;
  }

  &__type {
    margin-top: 30px;
  }
}

.list {
  margin-top: 25px;

  &__header {
    display: grid;
    grid-template-columns: 45% 45% 10%;
    padding-bottom: 5px;
    border-bottom: 1px dotted #eee;

    &__action {
      @include displayFlex(center);
    }
  }

  &__body {
  }

  &__row {
    display: grid;
    grid-template-columns: 45% 45% 10%;
    padding: 15px 0;
    border-bottom: 1px solid #eee;
  }

  &__action {
    @include displayFlex(center);
  }

  &__icon {
    height: 20px;
    width: 20px;
  }
}

.tenant__label{
  display: flex;
  align-items: center;
  color: $text-grey;
  font-size: 14px;
  margin-bottom: 5px;

  @include maxW_425 {
    margin-bottom: 5px;
  }
}
