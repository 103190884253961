@import 'assets/scss/variables';
@import 'assets/scss/mixins';

.twelfth {
  @include displayFlex(center);
  flex-wrap: wrap;

  &__header {
    width: 700px;
    text-align: center;
    @include displayFlex(null, center, column);

    @include maxW_425 {
      @include displayFlex(null, center, column);
      text-align: left;
      width: 375px;
      margin-top: 65px;
      padding-left: 30px;
    }
  }

  &__title {
    font-family: Poppins;
    font-size: 36px;
    font-weight: 500;
    font-style: normal;
    color: $main-white;

    @include maxW_425 {
      font: normal 600 24px/30px $main-font;
    }
  }

   &__cards {
    @include displayFlex(center);
      gap: 40px;

      @include maxW_425 {
         @include displayFlex(null, center, column);
          max-height: 300px;
          gap: 5px;
      }
  }
}
