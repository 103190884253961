@import 'assets/scss/variables';
@import 'assets/scss/mixins';


.startPage {
  &__header {
    text-align: center;

    @include maxW_425 {
      text-align: left;
      margin-top: 50px;
    }
  }

  &__title {
    font-family: Poppins;
    font-size: 36px;
    font-weight: 500;
    line-height: 54px;
    color: $main-white;

    @include maxW_425 {
      font-family: $main-font;
      font-size: 24px;
      line-height: 30px;
    }
  }

  &__subtitle {
    margin-top: 10px;
    font-size: 21px;
    line-height: 26px;
    color: $main-white;

    @include maxW_425 {
      margin-top: 8px;
      font-size: 14px;
      line-height: 18px;
    }
  }


  &__content {
    @include displayFlex(null, center, column);
    height: 100%;
    max-height: 210px;
    margin-top: 60px;
    padding: 0 30px;

    @include maxW_1080 {
      padding: 0;
    }

    @include maxW_768 {
      flex-direction: column;
      max-height: 100%;
    }

    @include maxW_425 {
      margin-top: 26px;
    }
  }

  &__form{
    @include displayFlex(null, null, row)
  }

  &__divider {
    height: 100%;
    width: 1px;
    background: rgba(255, 255, 255, 0.5);

    @include maxW_768 {
      height: 1px;
      width: 100%;
      margin: 20px 0;
    }
  }

  &__input {
    width: 240px;
    margin-right: 20px;

    @include maxW_768 {
      width: 100%;
    }

    @include maxW_425 {
      margin-bottom: 10px;
    }
  }

  &__agreements {
    margin-top: 24px;
    font-size: 14px;
    font-weight: 300;
    line-height: 18px;
    color: $main-white;

    @include maxW_425 {
      margin-top: 20px;
    }
  }

  &__agreement_link {
    color: $main-white;
  }
}
