@import 'assets/scss/variables';
@import 'assets/scss/mixins';

.newUser {
  @include displayFlex(center, center, column);
  margin-top: 100px;
  width: 100%;
  gap: 25px;

  &__radioGroup {
    gap: 20px;
    margin-left: 10px;
  }

  &__radioLabel {
    font-size: 14px;
    margin-left: -5px;
  }
}

.footer {
  @include displayFlex(space-between);
  gap: 40px;
  margin-bottom: 40px;

  @include maxW_425 {
    width: 390px;
  }
}
