@import 'assets/scss/mixins';

.proFormaSummary {
  display: flex;
  flex-direction: column;

  &__container {
    display: grid;
    row-gap: 15px;
  }

  &__graphics {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    height: 320px;

    @include maxW_425 {
      grid-template-columns: 1fr;
    }
  }

  &__content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column: 2;
    column-gap: 20px;
    min-width: 300px;

    @include maxW_425 {
      grid-column: 1;
    }
  }

  &__descriptions {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-left: 20px;

    @include maxW_580 {
      display: none;
    }
  }
}
