@import 'assets/scss/variables';
@import 'assets/scss/mixins';

.dashboardSort {
  &__container {
    width: 320px;
    padding: 10px;
    margin-top: 80px;
    margin-bottom: 50px;
  }

  &__label {
    margin-bottom: 5px;
    font-size: 14px;
    color: $custom-input-label;
  }

  &__buttons {
    margin-top: 10px;
    @include displayFlex();
    gap: 20px;
  }

  &__select {
    width: 200px;
    margin-top: 40px;
  }
}

.footer {
  @include displayFlex(space-between);
  padding: 10px;
  border-top: 1px solid rgb(185, 182, 182);
  background-color: $main-white;
  opacity: 1;
  position: sticky;
  bottom: 0;
  left: 0;
}
