@import 'assets/scss/variables';
@import 'assets/scss/mixins';

.LenderView {
  &__section {
    padding-bottom: 35px;
    border-bottom: 1px solid $border;

    &:last-of-type {
      border-bottom: none;
    }

    @include maxW_425 {
      padding: 0 10px 15px;
    }
  }

  &__loader {
    position: absolute;
    z-index: 2;
    @include displayFlex(center, center);
    width: 100%;
    height: 100%;
    border: 1px solid $border;
    border-radius: 10px;
    background-color: $main-white;
  }

  &__flexTable {
    @include displayFlex();
    flex-wrap: wrap;
    gap: 20px;
  }

  &__infoContentTitle {
    margin: 35px 0 15px;
    color: $light-blue;
    font: 500 30px $quote-terms-title-font;

    @include maxW_425 {
      margin: 0;
      font-size: 20px;
    }
  }
}
