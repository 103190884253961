@import 'assets/scss/variables';
@import 'assets/scss/mixins';

.equity {
  @include displayFlex(null, center, column);
  margin-top: 60px;
  gap: 40px;

  &__container {
    width: 95%;
  }

  &__title {
    font-size: 30px;
    color: $main-gray;
  }

  &__subtitleGroup {
    @include displayFlex(space-between);
    width: 90%;

    @include maxW_768 {
      width: 100%;
    }
  }

  &__subtitle {
    font-size: 20px;
    color: $main-gray;
  }

  &__sliderWrapper {
    width: 80%;
    @include displayFlex(space-between);

    @include maxW_768 {
      @include displayFlex(null, null, column);
      gap: 10px;
      width: 100%;
    }

    &_third {
      @include displayFlex(space-between);
      width: 88%;

      @include maxW_768 {
        gap: 10px;
        width: 100%;
      }
    }
  }

  &__btnGroup {
    @include displayFlex(flex-start);
    gap: 20px;
  }

  &__sliderContent {
    width: 185px;
    @include displayFlex(space-between, center);
    font-size: 18px;

    @include maxW_768 {
      width: 225px;
    }

    &_value {
      border: 1px solid $main-gray;
      border-radius: 6px;
      padding: 7px 20px;
    }

    &_subValue {
      white-space: nowrap;
      margin-left: 20px;
    }

    &_perc {
      @include maxW_768 {
        margin-right: 5px;
      }
    }

    &_price {
      @include maxW_768 {
        margin-right: 35px;
      }
    }
  }

  &__boxValue {
    @include displayFlex(center, center);
    width: 60px;
    height: 42px;
    border: 1px solid $main-gray;
    border-radius: 6px;

    @include maxW_768 {
      margin-right: 10px;
    }
  }

  &__boxWrapper {
    @include displayFlex(null, center);
    gap: 5px;
  }

  &__percentPartyTitle {
    font-size: 20px;
    color: $main-gray;
    margin: 30px 0 10px 0;

    @include maxW_768 {
      white-space: nowrap;
    }
  }

  &__percentEquity {
    @include displayFlex(space-between);
    width: 95%;

    @include maxW_768 {
      width: 75%;
    }
  }

  &__secondCol {
    @include displayFlex(null, flex-start, column);
    width: 23%;
  }

  &__btnContainer {
    @include displayFlex(null, null, column);
    margin: 40px 0 25px 0;
  }
}

.footer {
  @include displayFlex(space-between);
  padding: 24px 20px;
  border-top: 1px solid rgb(185, 182, 182);
  background-color: $main-white;
  opacity: 1;
  position: sticky;
  bottom: 0;
  left: 0;
}
