@import '/src/assets/scss/variables';
@import '/src/assets/scss/mixins';

.stepSelect {
  @include displayFlex(center, null, column);

  @include maxW_425 {
    margin-bottom: 10px;
  }
}
