@import 'assets/scss/mixins';

.Tenant {
  &__profile {
    @include displayFlex(0, center);
    margin-bottom: 25px;
  }

  &__photo {
    width: 70px;
    height: 70px;
    border-radius: 5px;
    object-fit: cover;
  }

  &__info {
    margin-left: 20px;
  }
}
