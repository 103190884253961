@import 'assets/scss/variables';
@import 'assets/scss/mixins';

.tenth {
  &__header {
    @include displayFlex(center, center, column);
    width: 740px;
    padding-bottom: 55px;

    @include maxW_425 {
      @include displayFlex(null, flex-start, column);
      width: 300px;
      margin-top: 65px;
      padding: 10px;
    }
  }

  &__title {
    font-family: Poppins;
    font-size: 36px;
    font-weight: 500;
    font-style: normal;
    color: $main-white;

    @include maxW_425 {
      margin-bottom: 10px;
      font-family: $main-font;
      font-size: 24px;
    }
  }

  &__subtitle {
    font: normal normal 21px/26px $main-font;
    color: $main-white;
    text-align: center;

    @include maxW_425 {
      font-size: 14px;
      line-height: 18px;
      text-align: start;
    }
  }

  &__textarea {
    padding: 0 90px;

    @include maxW_425 {
      padding: 0 20px 24px;
      width: 280px;
      height: 132px;
    }
  }
}

.saveBtn {
  display: inline-block;
  margin: 22px auto 42px;
  border-radius: 6px;
  background-color: $main-white;

  @include maxW_425 {
    margin-right: auto;
    margin-left: auto;
  }
}

.saveText {
  font: normal 600 16px/20px $main-font;
  padding: 5px;
  text-align: center;
  color: $save-button-text;
  text-transform: none;
}
