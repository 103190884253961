@import 'assets/scss/variables';
@import 'assets/scss/mixins';

.formaTable {
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  @include maxW_425 {
    grid-template-columns: 1fr;
  }

  &__titles {
    display: grid;
    grid-template-rows: repeat(4, 1fr);
    row-gap: 10px;
    justify-content: flex-end;
    margin-right: 20px;

    @include maxW_580 {
      display: none;
    }
  }

  &__title {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    text-align: end;
    color: $boulder-text;
  }

  &__content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, 1fr);
    row-gap: 10px;
    column-gap: 20px;
    min-width: 300px;

    @include maxW_580 {
      grid-column: 2;
    }

    @include maxW_425 {
      grid-column: 1;
    }
  }

  &__cell {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 600;

    &-highlighted {
      font-size: 12px;
      font-weight: 600;
      color: $boulder-text;

      @include minW_425 {
        display: none;
      }
    }

    &-addition {
      font-size: 13px;
      font-weight: 500;
    }
  }
}
