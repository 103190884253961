@import 'assets/scss/mixins';
@import 'assets/scss/variables';

.previewData {
  @include displayFlex(space-between);
  flex: 1;

  &__cell {
    @include displayFlex(null, space-between, column);
    flex: 1;
  }

  &__title {
    font-size: 14px;
    color: $text-grey;
  }
}
