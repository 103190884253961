@import 'assets/scss/variables';
@import 'assets/scss/mixins';

.document {
  @include displayFlex(null, center);
  cursor: pointer;

  &__content {
    @include displayFlex();
    flex: 1;
    padding: 12px;
    border: 1px solid $border;
    border-radius: 5px;


    @include maxW_425 {
      padding: 12px 0;
      border: none;
    }
  }

  &__icon {
    align-self: center;
    margin-right: 5px;
  }

  &__date {
    padding-left: 5px;
    color: $main-silver-chalice;
  }

  &__name {
    flex: 1;
    width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
