@import 'assets/scss/variables';
@import 'assets/scss/mixins';

.app {
  @include displayFlex(center);
  width: 100%;
  min-height: 100vh;

  &__content {
    width: 100%;
  }
}

.projectView {
  box-sizing: border-box;
  @include displayFlex(null, center, column);
  background: rgb(2, 0, 36);
  background: linear-gradient(180deg, rgba(2, 0, 36, 1) 0%, rgba(255, 255, 255, 1) 0%, rgba(221, 228, 228, 1) 100%);
  margin: 0 auto;

  @include maxW_1536 {
    max-width: 1000px;
  }

  @include maxW_425 {
    padding: 0;
    background: #fff;
  }

  &__wrapper {
    @include displayFlex(null, null, column);
    gap: 25px;
    max-width: 1000px;
    padding: 25px 0 125px;

    @include minW_1080 {
      min-width: 1000px;
    }

    @include maxW_768 {
      padding: 25px 0 0;
    }

    @include maxW_425 {
      position: relative;
      gap: 0;
      padding: 0;
      background-color: $main-white;
    }
  }

  &__cols {
    display: flex;
    gap: 20px;

    @include maxW_768 {
      justify-content: center;
      gap: 0;
    }

    &_withoutDeals {
      @include displayFlex(center);
    }
  }

  &__preview {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    column-gap: 20px;

    @include maxW_768 {
      @include displayFlex(0, 0, column);
    }

    @include maxW_425 {
      background-color: $main-white;
    }
  }

  &__sliderFade {
    width: 980px;
    height: 580px;
    outline: none;
    border-radius: 10px;
    background-color: $modal-gallery-slider-background;
    padding: 10px 0;
  }

  &__content {
    padding: 0 20px;
    border: 1px solid $border;
    border-radius: 10px;
    background-color: $main-white;

    @include maxW_425 {
      padding: 0;
      border: none;
      border-radius: 0;
    }
  }

  &__controller {
    display: none;
    min-height: 60px;
    padding: 0 10px;
    background-color: $main-white;

    @include maxW_425 {
      @include displayFlex(flex-end, center);
    }
  }
}
