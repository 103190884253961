@import 'assets/scss/variables';
@import 'assets/scss/mixins';

.dashboard {
  box-sizing: border-box;
  @include displayFlex(null, center, column);
  background: linear-gradient(180deg, rgba(2, 0, 36, 1) 0%, rgba(255, 255, 255, 1) 0%, rgba(221, 228, 228, 1) 100%);

  &__load {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -50px;
    margin-top: -50px;
  }

  &__projects {
    width: 100%;
    max-width: 1160px;
    margin-top: 16px;
    border: 1px solid $border;
    border-radius: 10px;
    padding: 20px;
    background: $main-white;
    margin-bottom: 44px;
    min-height: 800px;

    @include maxW_1280 {
      max-width: 95%;
      margin-bottom: 0;
    }

    @include maxW_425 {
      width: 100%;
      padding: 0;
      border: none;
    }
  }

  &__cardsView {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: center;
    gap: 20px;
    row-gap: 36px;
    margin-top: 16px;

    @media (max-width: 920px) {
      grid-template-columns: 1fr 1fr;
    }

    @include maxW_768 {
      gap: 10px;
    }

    @include maxW_580 {
      @include displayFlex(null, center, column);
    }
  }

  &__tableView {
    display: flex;
  }

  &__actions {
    @include displayFlex(center);
  }
}

.loadMore {
  border: none;
  background: none;
  font-size: 14px;
  color: $main-blue;
  margin-top: 24px;
  cursor: pointer;
  font-family: 'Poppins', serif;
}
