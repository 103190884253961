@import 'assets/scss/variables';
@import 'assets/scss/mixins';

.header {
  box-sizing: border-box;
  @include displayFlex(center, center);
  height: 80px;
  padding: 0;
  width: 100%;
  border-bottom: 1px solid $border;
  background-color: $main-white;

  @include maxW_425 {
    height: 90px;
    padding: 0;
    border-bottom: none;
  }

  &__container {
    @include displayFlex(space-between, center);
    width: 100%;
    max-width: 1100px;
  }

  &__left {
    @include displayFlex(center, center);
  }

  &__logo {
    width: 50px;
    height: 50px;

    @include maxW_1080 {
      padding-left: 10px;
    }

    @include maxW_768 {
      width: 30px;
      height: 30px;
    }
  }

  &__title {
    margin-left: 10px;
    font-size: 42px;
    font-weight: 500;
    text-decoration: none;
    color: $main-black;

    @include maxW_425 {
      font-size: 28px;
    }
  }

  &__right {
    @include displayFlex(0, center);
    gap: 24px;

    @include maxW_425 {
      margin-right: 20px;
    }
  }

  &__action {
    @include displayFlex(null, center);
    margin: 0;
    color: $light-gray;
    cursor: pointer;

    @include maxW_768 {
      display: none;
    }
  }

  &__dropdown {
    &__item {
      margin: 0;
      padding: 0;
      cursor: pointer;
    }

    @include maxW_768 {
      display: none;
    }
  }

  &__pathIcon {
    margin-left: 7px;
  }

  &__hide {
    margin-left: 7px;

    @include maxW_425 {
      display: none;
    }
  }
}

.learnMore {
  @include displayFlex();
  gap: 6px;
  font-size: 18px;

  @include maxW_768 {
    display: none;
  }

  &__link {
    color: $main-blue;
  }
}
