@import 'assets/scss/variables';
@import 'assets/scss/mixins';

.dataCell {
  box-sizing: border-box;
  flex: 25%;
  padding-right: 5px;

  &__title {
    color: $text-grey;
    font-size: 14px;
    font-weight: 600;

    &_reversed {
      font-size: 16px;
      color: $main-black;
    }
  }

  &__subtitle {
    font-size: 18px;
    font-weight: 600;
    
    &_reversed {
      font-weight: 400;
    }
  }

  &__edit {
    margin-left: 10px;
    font-size: 13px;
  }
}
