@import 'assets/scss/mixins';
@import 'assets/scss/variables';

.attachments {
  &__head {
    @include displayFlex(space-between, center);

    @include maxW_675 {
      @include displayFlex(null, flex-start, column);
    }

    @include maxW_425 {
      display: none;
    }
  }

  &__sectionTitle {
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: 600;

    &:first-of-type {
      margin-top: 10px;
    }
  }

  &__documents {
    @include displayFlex(null, null, column);
    gap: 30px;
    margin-bottom: 35px;

    @include maxW_425 {
      gap: 5px;
      margin-bottom: 15px;
    }

    @include maxW_375 {
      gap: 0;
      margin-bottom: 10px;
    }
  }

  &__buttons {
    @include maxW_675 {
      margin-top: 15px;
    }
  }
}
