@import 'assets/scss/variables';
@import 'assets/scss/mixins';

.browser {
  box-sizing: border-box;
  @include displayFlex(center, center);
  padding: 0;
  margin: auto;
  width: 900px;
  background-color: $main-white;

  @include maxW_425 {
    height: 380px;
    padding: 0;
  }

  @media screen and (min-width: 425px) and (max-width: 820px) {
    width: 90%;
  }
  &__container {
    padding: 20px;
    margin-top: 24px;
    border-radius: 10px;
    border: 1px solid $loan-request-title-color;

    @include maxW_425 {
      width: auto;
    }
  }

  &__title {
    font: normal 500 30px/45px 'Poppins';
    margin: 0;
    color: $loan-request-title-color;

    @include maxW_425 {
      font-size: 28px;
    }
  }

  &__text {
    font-size: 16px;
    color: $main-gray;
    margin-top: 20px;
    width: 80%;
  }

  &__list {
    @include displayFlex(center);
    margin: 70px 0;
    padding: 0;
  }

  &__item {
    @include displayFlex(null, center, column);
    list-style: none;
    margin-right: 70px;
  }

  &__link {
    font-size: 16px;
    color: $main-blue;
    text-decoration: underline;
    margin-top: 20px;
  }

  &__linkMail {
    font-size: 18px;
    color: $main-blue;
    text-decoration: underline;
  }
}
