@import 'assets/scss/variables';
@import 'assets/scss/mixins';

.sixth {
  &__header {
    text-align: center;

    @include maxW_425 {
      text-align: left;
      margin-top: 75px;
    }
  }

  &__title {
    @include createNewOfferTitle(36px);

    @include maxW_425 {
      font-family: $main-font;
      font-size: 24px;
    }
  }

  &__inputs {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
    width: 650px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    padding: 30px 0;

    @include maxW_425 {
      grid-template-columns: repeat(1, 1fr);
      gap: 20px;
      width: 300px;
    }
  }

  &__label {
    display: block;
    color: $main-white;
    font: normal 500 14px/18px $main-font;
    padding-bottom: 9px;
  }

  &__totalWrapper {
    @include displayFlex(space-between);
    margin-top: 18px;
  }

  &__total {
    display: block;
    color: $main-white;
    font: normal bold 14px/18px $main-font;
  }

  &__add {
    display: flex;
  }

  &__addIcon {
    @include displayFlex(center, center);
    width: 14px;
    height: 14px;
    margin-right: 4px;
    margin-top: 2px;
    border-radius: 50%;
    background-color: $main-white;
    color: $main-blue;
  }

  &__addItem {
    color: $main-white;
    font: normal normal 14px/18px $main-font;
  }
}
