@import 'assets/scss/variables';
@import 'assets/scss/mixins';

.fifth {
  &__header {
    text-align: center;

    @include maxW_425 {
      text-align: left;
      height: 50px;
      margin-top: 75px;
    }
  }

  &__title {
    font-family: Poppins;
    font-size: 36px;
    font-weight: 500;
    color: $main-white;

    @include maxW_425 {
      font-family: $main-font;
      font-size: 24px;
    }
  }

  &__form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;

    @include maxW_425 {
      grid-template-columns: repeat(1, 1fr);
      gap: 1px;
    }
  }

  &__formSelect {
    @include displayFlex(null, null, column);
    gap: 10px;
    margin-top: 13px;
  }

  &__label {
    @include displayFlex();
    padding: 15px 0 9px;
    color: $main-white;
    font: normal 600 14px/18px $main-font;
  }

  &__input {
    display: block;
    outline: none;
    margin-top: 10px;
    padding: 10px 12px;
    height: 42px;
    border-radius: 6px;
    box-sizing: border-box;
    background: none;
    width: 300px;
    font-size: 14px;
    &::placeholder {
      color: $main-white;
    }
  }

  &__title {
    @include createNewOfferTitle(36px);

    @include maxW_425 {
      margin-bottom: 10px;
      font-family: $main-font;
      font-size: 24px;
    }
  }
}
