@import 'assets/scss/variables';
@import 'assets/scss/mixins';

.Underwriting {
  flex-grow: 1;
  min-height: 240px;
  padding: 0 20px;
  border: 1px solid $border;
  border-radius: 10px;
  background-color: $main-white;

  @include maxW_768{
    max-width: calc(100vw - 2px);
    border-radius: 0;
    padding: 0;
  }

  @include maxW_425 {
    margin-bottom: 60px;
  }

  &__heading {
    margin: 15px 0 15px;
    color: #8394a5;
    font: 500 30px Poppins;

    @include maxW_768 {
      padding-left: 10px;
    }
  }

  &__sliderFade {
    width: 980px;
    height: 580px;
    outline: none;
    border-radius: 10px;
    background-color: $modal-gallery-slider-background;
    padding: 10px 0;
    cursor: default;
  }
}

.Tab {
  &__heading {
    color: #505050;
    font: 600 18px 'Source Sans Pro';
    letter-spacing: 0;
    line-height: 20px;

    @include maxW_425 {
      margin-left: -6px;
    }
  }

  &__row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    align-items: flex-start;
    margin-bottom: 35px;
  }

  &__label {
    color: #757575;
    font-size: 14px;
    margin-bottom: 10px;
  }

  &__assetType {
    @include displayFlex(null, center);
    gap: 10px;
  }

  &__link {
    color: $main-blue;
  }
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: zoom-out;
}
