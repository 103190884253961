@import 'assets/scss/variables';
@import 'assets/scss/mixins';

.profile {
  box-sizing: border-box;
  @include displayFlex(null, center, column);
  height: 100vh;
  background: linear-gradient(180deg, rgba(2, 0, 36, 1) 0%, rgba(255, 255, 255, 1) 0%, rgba(221, 228, 228, 1) 100%);

  &__container {
    box-sizing: border-box;
    position: relative;
    height: fit-content;
    width: 980px;
    margin-top: 25px;
    padding: 25px 20px;
    border: 1px solid $border;
    border-radius: 10px;
    background-color: $main-white;

    @include maxW_1080 {
      width: 100%;
    }

    @include maxW_425 {
      margin: 0;
      border-radius: 0;
    }
  }

  &__title {
    margin: 0;
    font: $createNewOffer-title-font;
    font-size: 30px;
    font-weight: 500;
    color: $light-gray;
  }

  &__label {
    font-size: 14px;
    margin-bottom: 5px;
    color: $light-gray;
  }

  &__personalRow {
    display: grid;
    grid-template-columns: 250px 250px 250px;
    gap: 24px;
    margin-bottom: 24px;

    @include maxW_768 {
      display: flex;
      flex-direction: column;
    }

    @include maxW_425 {
      display: flex;
      flex-direction: column;
    }
  }

  &__contactRow {
    display: grid;
    grid-template-columns: repeat(2, 250px);
    gap: 24px;
    margin-bottom: 24px;

    &__col {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    @include maxW_425 {
      display: flex;
      flex-direction: column;
    }
  }

  &__addressRow {
    display: grid;
    grid-template-columns: 250px 620px;
    gap: 24px;
    margin: 40px 0 24px;

    &__col {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    @include maxW_1080 {
      display: flex;
      flex-direction: column;
    }

    @include maxW_425 {
      margin: 0 0 24px;
    }
  }

  &__sessions {
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 15px 20px;
  }

  &__group {
    display: flex;
    align-items: flex-end;
    gap: 20px;
  }
}

.photo {
  @include displayFlex(null, center);
  gap: 24px;
  margin-top: 24px;
  margin-bottom: 24px;

  &__label {
    input[type='file'] {
      display: none;
    }
  }

  &__actions {
    button {
      margin-right: 20px;
    }
  }

  @include maxW_425 {
    display: flex;
    flex-direction: column;
  }
}
