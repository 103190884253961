@import 'assets/scss/variables';
@import 'assets/scss/mixins';

.ninth {
  @include maxW_425 {
    width: 100%;
  }

  &__header {
    text-align: center;

    @include maxW_425 {
      text-align: left;
      margin-top: 75px;
    }
  }

  &__title {
    @include createNewOfferTitle(36px);
    margin-bottom: 25px;

    @include maxW_425 {
      margin-bottom: 10px;
      font-family: $main-font;
      font-size: 24px;
    }
  }

  &__row {
    @include displayFlex(space-between, center);
    margin-bottom: 10px;
  }

  &__label {
    @include displayFlex(null, center);
    color: $main-white;
  }

  &__hint {
    @include displayFlex(center, center);
    height: 14px;
    width: 14px;
    margin-left: 5px;
    background-color: $main-white;
    color: #000;
    border-radius: 50%;
    text-align: center;
    cursor: default;
  }

  &__upload {
    padding: 10px 20px;
    border: 1px solid $main-white;
    border-radius: 6px;
    background: none;
    color: $main-white;
    font-size: 14px;
    cursor: pointer;

    input[type='file'] {
      display: none;
    }
  }

  &__attachments {
    @include displayFlex(center, null, column);
  }

  &__drop {
    @include displayFlex(center, center);
    min-height: 110px;
    margin-bottom: 25px;
    border: 1px dashed $main-white;
    border-radius: 6px;
  }

  &__list {
    @include displayFlex(null, null, column);
    gap: 15px;
    margin-bottom: 15px;

    @include maxW_425 {
      gap: 25px;
      margin-bottom: 35px;
    }
  }

  &__fileRow {
    @include displayFlex(null, center);
    position: relative;
    padding: 10px 15px;
    border: 1px solid $main-white;
    border-radius: 6px;
    color: $main-white;
  }

  &__fileDelete {
    position: absolute;
    right: -20px;
    height: 14px;
    width: 10px;
    background: url('../../../../assets/icons/delete.svg');
    cursor: pointer;

    @include maxW_425 {
      bottom: -20px;
      right: 0;
    }
  }

  &__gallery {
    max-height: 200px;
    margin-bottom: 30px;
    padding-right: 15px;
    display: grid;
    grid-template-columns: repeat(4, 150px);
    gap: 15px;
    justify-content: center;
    overflow-y: auto;

    @include maxW_425 {
      grid-template-columns: repeat(2, 150px);
      padding-right: 0;
    }
  }

  &__card {
    position: relative;
    height: 100px;
    width: 150px;
    border-radius: 6px;
  }

  &__removeBtn {
    position: absolute;
    right: 0;
    height: 14px;
    width: 14px;
    margin: 10px;
    background: url('../../../../assets/icons/remove.svg');
    cursor: pointer;
  }

  &__image {
    height: 100%;
    width: 100%;
    border-radius: 6px;
  }
}
