@import 'assets/scss/variables';
@import 'assets/scss/mixins';

.pictures {
  margin: 30px 0;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 15px;
  justify-content: center;

  @include maxW_425 {
    grid-template-columns: repeat(2, 150px);
    padding-right: 0;
  }

  &__row {
    @include displayFlex(space-between, center);
  }

  &__heading {
    color: $main-blue;
    font-size: 14px;
  }

  &__upload {
    padding: 8px 25px;
    border-radius: 6px;
    background-color: $main-blue;
    color: $main-white;
    font-weight: bold;
    text-transform: none;
    cursor: pointer;

    & input[type='file'] {
      display: none;
    }

    @include maxW_425 {
      padding: 8px;
    }
  }
}

.gallery {
  margin-top: 25px;

  @include maxW_425 {
    display: none;
  }

  &__content {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
    margin: 10px 0 35px;
  }

  &__item {
    position: relative;
    max-height: 100px;
    min-height: 100px;
    border-radius: 10px;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
    cursor: zoom-in;
    transition: transform 0.1s ease-in;

    &:hover {
      transform: scale(1.05);
    }
  }

  &__removeBtn {
    position: absolute;
    top: 0;
    right: 0;
    height: 14px;
    width: 14px;
    margin: 10px;
    background: url('../../../../assets/icons/remove.svg');
    cursor: pointer;
    z-index: 100;
  }
}
