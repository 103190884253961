.description {
  display: flex;
  align-items: flex-end;
  margin-top: 20px;

  &__title {
    font-size: 13px;
    font-weight: 600;
    color: #787878;
  }

  &__view {
    width: 25px;
    height: 25px;
    margin-right: 10px;

    &-darkened {
      background-color: #0a6f66;
    }

    &-light {
      background-color: #4db79e;
    }
  }
}
